(function($) {
    var exeWidgetErrorHandler, exeWidgetSuccessHandler;
    $.fn.exeWidgetInit = function(options) {
      var $widget, $widgetRefreshHandler, url, method, data, successCallback, errorCallback, isLoading = false, ajaxHandler;

      if (options === null) {
        options = {};
      }

      $widget = $(this);
      $widgetId = $(this).attr('id');

      $widgetRefreshHandler = '<button type="button" class="btn-block-option">';
      $widgetRefreshHandler += '<i class="si si-refresh"></i>';
      $widgetRefreshHandler += '</button>';

      $widgetRefreshHandler = $($widgetRefreshHandler);

      $widgetRefreshHandler.click(function() {
        if (isLoading === false || $widget.isLoading === false) {
          ajaxHandler();
        }
      });

      $widget.parent().find('.block-header .block-options').html($widgetRefreshHandler);

      if (typeof options === "function") {
        options.success = options;
      }

      successCallback = options.success;
      errorCallback = options.error;
      method = options.method || "get";
      url = options.url;
      data = options.data;

      ajaxHandler = function () {
          return $.ajax({
            type: method,
            url: url,
            data: data,
            cache:false,
            statusCode: {
              401: function() {
                window.location.reload();
              },
              403: function() {
                window.location.reload();
              },
              419: function() {
                $('#modal-token-expire').modal({
                  backdrop: 'static',
                  keyboard: false
                });
              }
            },
            beforeSend: function () {
              $widget.children('.ajax-loader').remove();

              var html = '<div class="ajax-loader d-flex justify-content-center">';
              html += '<div class="align-self-center">';
              html += '<img src="/media/loader-inline.svg" alt="loading..." style="width: 100px;">';
              html += '</div>';
              html += '</div>';

              $widget.removeClass('ajax-widget-loader').addClass('ajax-widget-loader');
              $widget.prepend(html);
              $widget.css('min-height', '230px');

              $widget.removeClass('position-relative').addClass('position-relative');
              if ($widget.is(":visible")) {
                $widget.children('.ajax-loader').css('height', ''+ $widget.innerHeight() +'px');
                $widget.children('.ajax-loader').css('width', ''+ $widget.innerWidth() +'px');
              } else {
                $widget.children('.ajax-loader').css('height', '230px');
                $widget.children('.ajax-loader').css('width', '100%');
              }

              isLoading = true;
            },
            success: function(data, status, xhr) {
                return exeWidgetSuccessHandler($widget, data, xhr, successCallback, errorCallback);
            },
            error: function(xhr, status, str) {
              $widget.children('.ajax-loader').remove();
              $widget.removeClass('position-relative');
              $widget.removeClass('ajax-widget-loader');

              isLoading = false;

              return exeWidgetErrorHandler($widget, xhr, status, errorCallback);
            }
        });
      }

      return ajaxHandler();
    };

    exeWidgetSuccessHandler = function($widget, data, xhr, successCallback, errorCallback) {
      var contentType = xhr.getResponseHeader("content-type") || "";

      if (contentType.indexOf('text/html') === -1) {
        if ($.isEmptyObject(data && data.errors)) {
          if (data.message) {
            Codebase.helpers('notify', {
              message: data.message,
              align: 'right',
              from: 'top',
              type: 'success',
              delay: 0,
              animate_enter: 'animated fadeIn',
              animate_exit: 'animated fadeOutDown'
            });
          }

          if (typeof successCallback === "function") {
            return successCallback($widget, data);
          }
        } else {
          if (typeof errorCallback === "function") {
            return errorCallback($widget, data);
          }

          if (data.message) {
            Codebase.helpers('notify', {
              message: xhr.responseJSON.message,
              align: 'right',
              from: 'top',
              type: 'danger',
              delay: 0,
              animate_enter: 'animated fadeIn',
              animate_exit: 'animated fadeOutDown'
            });
          }
        }
      } else {
        $widget.children('.ajax-loader').remove();
        $widget.html(data);

        if ($widget.find('img').length) {
          var html = '<div class="ajax-loader d-flex justify-content-center">';
          html += '<div class="align-self-center">';
          html += '<img src="/media/loader-inline.svg" alt="loading..." style="width: 100px;">';
          html += '</div>';
          html += '</div>';

          $widget.removeClass('ajax-widget-loader').addClass('ajax-widget-loader');
          $widget.prepend(html);
          $widget.css('min-height', '230px');

          $widget.removeClass('position-relative').addClass('position-relative');
          if ($widget.is(":visible")) {
            $widget.children('.ajax-loader').css('height', ''+ $widget.innerHeight() +'px');
            $widget.children('.ajax-loader').css('width', ''+ $widget.innerWidth() +'px');
          } else {
            $widget.children('.ajax-loader').css('height', '230px');
            $widget.children('.ajax-loader').css('width', '100%');
          }

          $widget.isLoading = true;

          $widget.imagesLoaded()
          .always( function( instance ) {
            $widget.children('.ajax-loader').remove();
            $widget.removeClass('position-relative');
            $widget.removeClass('ajax-widget-loader');

            $widget.isLoading = false;
          })
          .done( function( instance ) {
            console.log('all images successfully loaded');
          })
          .fail( function() {
            console.log('all images loaded, at least one is broken');
          })
          .progress( function( instance, image ) {
            var result = image.isLoaded ? 'loaded' : 'broken';
            console.log( 'image is ' + result + ' for ' + image.img.src );

            if (!image.isLoaded) {
              var html = '<div class="text-center">';
              html += '<p class="mb-0">Sorry we are unable to load the requested resource.</p>';
              html += '</div>';
              $(html).insertAfter(image.img);
              $(image.img).remove();
            }
          });
        } else {
          $widget.children('.ajax-loader').remove();
          $widget.removeClass('position-relative');
          $widget.removeClass('ajax-widget-loader');

          $widget.isLoading = false;
        }

        if (typeof successCallback === "function") {
          return successCallback($widget, data);
        }
      }
    };

    exeWidgetErrorHandler = function($widget, xhr, status, errorCallback) {
      if (typeof xhr.responseJSON != 'undefined' && xhr.responseJSON.errors) {
        if (xhr.responseJSON.message) {
          Codebase.helpers('notify', {
            message: xhr.responseJSON.message,
            align: 'right',
            from: 'top',
            type: 'danger',
            delay: 0,
            animate_enter: 'animated fadeIn',
            animate_exit: 'animated fadeOutDown'
          });
        }

        var html = '<div class="text-center">';
        html += '<p class="mb-0">Sorry we couldn\'t find any results.</p>';
        html += '</div>';

        $widget.html(html);
      } else if (xhr.status >= 400 && xhr.status < 500) {
        var html = '<div class="text-center">';
        html += '<p class="mb-0">Sorry we couldn\'t find any results.</p>';
        html += '</div>';

        $widget.html(html);
      } else if (xhr.status >= 500) {
        var html = '<div class="alert alert-warning d-flex align-items-center justify-content-between mb-15" role="alert">';
        html += '<div class="flex-fill mr-10">';
        html += '<p class="mb-0">we are unable to process your request at this time. please try again later.</p>';
        html += '</div>';
        html += '<div class="flex-00-auto">';
        html += '<i class="fa fa-fw fa-2x fa-exclamation-triangle"></i>';
        html += '</div>';
        html += '</div>';

        $widget.html(html);
      }
      if (typeof errorCallback == "function"){
          return errorCallback($widget, xhr)
      }
    }

    return $.fn.exeWidget = function(options) {
      if (options === null || typeof options === 'undefined') {
        options = {};
      }

      return $(this).exeWidgetInit(options);
    };
})(jQuery);
