window._ = require('lodash');
require('./exe.form');
require('./exe.widget');
require('./exe.table');
import Inputmask from "inputmask";
import Swal from 'sweetalert2';

window.Inputmask = Inputmask;
window.Swal = Swal.mixin({
  allowOutsideClick: false
});

window.Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});

window.queue = require('queue');
window.imagesLoaded = require('imagesloaded');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

$.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

window.linkify = function (inputText) {
  var replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

  //Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  return replacedText;
}

window.getSalutation = function(str) {
  var arr = str.split(' ');
  if(_.includes(['Mr', 'Ms', 'Mrs', 'Miss', 'Dr', 'Prof', 'Rev'], arr[0].trim())) {
      return arr[0].trim();
  }
  return '';
}

window.getFirstName = function(str) {
  var arr = str.split(' ');
  if( arr.length === 1 ) {
      return arr[0].replace(getSalutation(str), '').trim();
  }
  return arr.slice(0, -1).join(' ').replace(getSalutation(str), '').trim();
}

window.getLastName = function(str, ifNone) {
  var arr = str.split(' ');
  if(arr.length === 1) {
      return ifNone || '';
  }
  return arr.slice(-1).join(' ').replace(getSalutation(str), '').trim();
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });
